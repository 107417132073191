import Login from "../View/Onboarding/Login/Login";
import GetMail from "../View/Onboarding/GetMail/GetMail";
import GetOtp from "../View/Onboarding/GetOtp/GetOtp";
import PasswordReset from "../View/Onboarding/PasswordReset/PasswordReset";

export const PublicRoutes = [
  {
    element: <Login />,
    path: "/login",
  },
  {
    element: <GetMail />,
    path: "/getEmail",
  },
  {
    element: <GetOtp />,
    path: "/getOtp",
  },
  {
    element: <PasswordReset />,
    path: "/passwordreset",
  },
];
