import React, { useState } from "react";
import CreateFAQ from "./AddQuestion/AddQuestion";

const Questions = () => {
 
  const [questionDisplay, setQuestionDisplay] = useState(true);

  return (
    <>
      <div>
        <div className="d-flex justify-content-end">
          <button
            className="btn mx-2 border-0 "
            onClick={() => setQuestionDisplay(false)}
          >
            <u className="h4">Create New</u>
          </button>
        </div>

        <div className={questionDisplay ? "d-none" : "d-block"}>
          <CreateFAQ />
        </div>
      </div>
    </>
  );
};

export default Questions;
