import Dashboard from "../View/Dashboard/Dashboard";
import ManageContent from "../View/ManageContent/ManageContent";
import ReportFeed from "../View/ReportedManagement/ReportFeed";
import ReportFeedDetail from "../View/ReportedManagement/ReportFeedDetail";
import ReportedManagement from "../View/ReportedManagement/ReportedManagement";

import SubscribtionManagement from "../View/SubscribtionManagement.js/SubscribtionManagement";
import { UserDetail } from "../View/UserManagement/UserDetails/UserDetail";
import UserManagement from "../View/UserManagement/UserManagement";

export const PrivateRoutes = [
  {
    element: <UserManagement />,
    path: "userManagement",
    headerTitle: "User Management",
  },
  {
    element: <UserDetail />,
    path: "userManagement/profile/:id",
    headerTitle: "User Profile",
  },
  {
    element: <ManageContent />,
    path: "manageContent",
    headerTitle: "Manage Content",
  },
  {
    element: <Dashboard />,
    path: "dashboard",
    headerTitle: "DashBoard",
  },
  {
    element: <SubscribtionManagement />,
    path: "subscribtionManage",
    headerTitle: "Subscribtion Management",
  },
  {
    element: <ReportedManagement />,
    path: "reportManagement",
    headerTitle: "Reported User Management",
  },
  {
    element: <ReportFeed />,
    path: "reportsFeed",
    headerTitle: "Reported User Management",
  },
  {
    element: <ReportFeedDetail />,
    path: "reportsFeed/:id",
    headerTitle: "Reported User Management",
  },
];
