import React from "react";
import Header from "../../Components/Header/Header";

const SubscribtionManagement = () => {
  return (
    <>
      <Header title="Subscribtion Management" />
    </>
  );
};

export default SubscribtionManagement;
