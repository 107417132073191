import { CCol, CImage } from "@coreui/react";
import React from "react";
import appLogo from "../../assets/Icons/Frame.svg";

const PublicLayout = (props) => {
  return (
    <>
      <div className="row m-0" style={{ background: "#FFDCA2" }}>
        <CCol
          className="d-flex justify-content-center align-self-center"
          sm={6}
        >
          <CImage src={appLogo} width={400} height={300} />
        </CCol>
        <CCol>{props.children}</CCol>
      </div>
    </>
  );
};

export default PublicLayout;
