import React from "react";
import {
  CModal,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";

const Model = ({
  title,
  body,
  handleChange,
  handleClick,
  visible,
  setVisible,
}) => {
  return (
    <>
      <CModal className="" visible={visible} onClose={() => setVisible(false)}>
        {/* <CModalHeader onClose={() => props.setVisible}> */}
        <CModalTitle className="d-flex justify-content-center">
          {title}
        </CModalTitle>
        {/* </CModalHeader> */}
        <CModalBody className="d-flex justify-content-center">
          {body}
        </CModalBody>
        <CModalFooter className="d-flex justify-content-center">
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Cancel
          </CButton>
          <CButton color="danger" onClick={() => handleClick()}>
            {handleChange}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default Model;
