import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import "./ManageContent.css";
import About from "./AboutUs/About";
import Privacy from "./PrivacyPolicy/Privacy";
import Questions from "./FAQs/Questions";
const ManageContent = (props) => {
  const [currentTab, setCurrentTab] = useState("about");
  const handleContentClick = (tab) => {
    setCurrentTab(tab);
  };

  const renderTabContents = () => {
    if (currentTab === "about") return <About />;
    else if (currentTab === "privacy") return <Privacy />;
    else if (currentTab === "faq") return <Questions />;
  };
  return (
    <>
      <Header title="Manage Content" />
      <div className="mt-4 my-4">
        <button
          className="btn mx-2  border-0 text-white "
          onClick={() => handleContentClick("about")}
          style={{ fontSize: "20px", fontWeight: "500", background: "#C73866" }}
        >
          About
        </button>
        <button
          className="btn mx-2 border-0 text-white "
          onClick={() => handleContentClick("privacy")}
          style={{ fontSize: "20px", fontWeight: "500", background: "#C73866" }}
        >
          Privacy Policy
        </button>
        <button
          className="btn mx-2 border-0 text-white "
          onClick={() => handleContentClick("faq")}
          style={{ fontSize: "20px", fontWeight: "500", background: "#C73866" }}
        >
          FAQ
        </button>
      </div>
      {renderTabContents()}
    </>
  );
};

export default ManageContent;
