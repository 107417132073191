import React, { useEffect,useState } from "react";
import Header from "../../Components/Header/Header";
import { ArrowLeft } from "react-bootstrap-icons";
import { CImage } from "@coreui/react";
import { useNavigate, useParams } from "react-router-dom";
import "./ReportManagement.css"
import Model from "../../Components/Model/Model";
import { getRequest, putRequest } from "../../Constants/apiMethods";
import { deleteFeed, feedDetail } from "../../Constants/apiRoutes";
import moment from "moment";
import { toast } from "react-toastify";




const ReportFeedDetail = () => {
  const navigate = useNavigate();
  const[deteleFeed,setDeleteFeed]=useState(false)
  const[feedDetails,setFeedDetail]=useState("")
  const handleDeleteFeed=()=>{
    putRequest(deleteFeed+feedid)
    .then((response)=>{
      toast.success(response.data.data.message);
      setDeleteFeed(false)
      navigate('/reportsFeed')
    })
    .catch((error)=>{
      console.log(error);    

     setDeleteFeed(false)    })
    
  }
  const feedid=useParams().id;
  const getFeedDetail=()=>{
    getRequest(feedDetail+feedid)
    .then((response)=>{
      setFeedDetail(response?.data?.data?.[0])
    })
    .catch((error)=>{
      console.log(error);
    })

  }
  useEffect(() => {
    getFeedDetail()
  }, [])
  
  return (
    <>
      <Header title="Reported Feed Detail" />
      <Model
        title="Delete Feed"
        body="Are you sure to delete this feed ?"
        handleChange="Delete"
        handleClick={handleDeleteFeed}
        visible={deteleFeed}
        setVisible={setDeleteFeed}
      />
      <div>
        <ArrowLeft role="button" size={26} onClick={() => navigate(-1)} />
      </div>
      <div className=" border  rounded bg-white mt-4">
        <div>
          <h5
            className="p-3 backGroundColor  text-white border-bottom rounded-top rounded-bottom"
           
          >
            Feed Details
          </h5>
        </div>
        <div className="row ">
          <div className="col-8   ">
            
              <ul className="list-unstyled ps-3">
                <li className="py-2">
                  <h5>
                    Author Name:
                    <span className="ms- primaryTextColor" >
                      {" "}
                      {feedDetails?.author_name}
                    </span>
                  </h5>
                </li>
                <li className="py-2">
                  <h5>
                    Created Feed Date Time:
                    <span className="ms-2 primaryTextColor" >
                    {moment(feedDetails?.created_date_time).format("MM-DD-YYYY || hh:mm A")}
                     
                    </span>
                  </h5>
                </li>  
                <li className="py-2">
                  {" "}
                  <h5>
                    Report Count:
                    <span className="ms-2 primaryTextColor">
                      {feedDetails?.report_count}
                    </span>
                  </h5>
                </li> 
                <li className="py-2 ">
                  <h5>
                    Feed Description:
                    <span className="ms-2 primaryTextColor" >
                      {" "}
                      {feedDetails?.caption}
                    </span>
                  </h5>
                </li>            
              </ul>
                        
          </div>
          <div className="col-3 mb-2">
          {feedDetails?.feed_image?.length>=1&&<CImage
          className="w-100"
          width="320" height="240"
              
              src={feedDetails?.feed_image?.[0]}
              alt="Feed Photo Is Not Available"
            />}
            
       {feedDetails?.feed_video?.length>=1&&
       <video width="320" height="240" controls>
       <source src={feedDetails?.feed_video?.[0]} type="video/mp4"/>
       Your browser does not support the video tag.
     </video>
     
        }
       
      
          </div>
        </div>
        
      </div>
      <div className="d-flex align-items-center justify-content-center ">
        <button type="button" 
        className="mt-5 deleteButton"
        onClick={()=>setDeleteFeed(true)}
        >
        Delete Feed
        </button>
      </div>
      
    </>
  );
};

export default ReportFeedDetail;
