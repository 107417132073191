import React from "react";
import { Navigate } from "react-router-dom";
import Navbar from "../Sidebar/Navbar";


export default function PrivateLayout(props) {

const token=localStorage.getItem('token');
    return (
      token?
      <div className="">
        <div className="d-flex">
          <Navbar className="" />

          <div className=" me-1  w-100 px-4" style={{ background: "#ffefd5" }}>
            {props.children}
          </div>
        </div>
      </div>:<Navigate replace to="/login" />
    );

}
