import React from "react";
import { useForm } from "react-hook-form";
import { ArrowLeft } from "react-bootstrap-icons";
import { toast } from "react-toastify";

import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import PublicLayout from "../../../Components/PublicLayout/PublicLayout";
import { forgot_password } from "../../../Constants/apiRoutes";
import { postRequest } from "../../../Constants/apiMethods";
const GetMail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    
      postRequest(forgot_password, data)
        .then((_res) => {
          navigate("/passwordreset");
          toast.success("OTP sent on registered Email");
        })
        .catch((error) => {
          toast.error(error.response.data.error.message);
          console.log("Error Message===>", error.message);
        })
    
  };

  return (
    <>
      <PublicLayout>
        <div className=" min-vh-100 d-flex w-50 flex-row align-items-center ">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol>
                <CForm onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-5">
                    <ArrowLeft
                      role="button"
                      size={26}
                      onClick={() => navigate("/login")}
                    />
                  </div>
                  <div>
                    <h1
                      className=" login-heading-label"
                      style={{
                        fontWeight: 400,
                        fontSize: "35px",
                      }}
                    >
                      Forgot Password
                    </h1>
                    <p
                      className="text-medium-emphasis"
                      style={{ fontWeight: 400, fontize: "18px" }}
                    >
                      Please provide your email and a verification OTP will be
                      sent to your email address.
                    </p>
                  </div>
                  <p className="text-medium-emphasis">Email </p>
                  <CInputGroup className="mb-3 ">
                    <CFormInput
                      autoComplete="off"
                      {...register("email", {
                        required: true,
                        pattern:
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      })}
                      style={{
                        borderRadius: "10px",
                        padding: "10px",
                      }}
                      placeholder=" Email address"
                    />
                  </CInputGroup>
                  {errors.email?.type === "required" && (
                    <p className="text-danger text-xs ">Email is required</p>
                  )}
                  {errors.email?.type === "pattern" && (
                    <p className="text-danger  text-xs ">
                      Please enter a valid Email address
                    </p>
                  )}
                  <CRow>
                    <CCol className="d-flex justify-content-start ">
                      <CButton
                        className="px-4 py-3"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          background: "#C73866",
                          color: "white",
                          border: "0",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                        type="submit"
                      >
                        Submit
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </PublicLayout>
    </>
  );
};
export default GetMail;
