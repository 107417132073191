import React from "react";

const CreateFAQ = () => {
  return (
    <>
      {/* <div className="mb-4">
        <ArrowLeft role="button" size={26} />
        <span className="ms-2">Create FAQ</span>
      </div> */}
      <div>
        <p className="text-medium-emphasis">Question </p>

        <input />
        <p className="text-medium-emphasis">Answer </p>

        <input />
      </div>
    </>
  );
};

export default CreateFAQ;
