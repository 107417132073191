import React from "react";
import { NavLink } from "react-router-dom";

import logoIcon from "../../assets/Icons/Vector.png";
import appLogo from "../../assets/Icons/Frame.svg";
import userManagementIcon from "../../assets/Icons/userManagementIcon.svg";
import reportedFeed from "../../assets/Icons/reportedFeed.svg";
import reportedUsers from "../../assets/Icons/reportedUsers.svg"
import logoutIcon from "../../assets/Icons/logout.svg";
import "./Navbar.css";
import { CImage } from "@coreui/react";
const Navbar = () => {
  const sideBarData = [
    // {
    //   tittle: "Dashboard",
    //   icon: iconDash,
    //   url: "/dashboard",
    // },
    {
      tittle: "User Management",
      icon: userManagementIcon,

      url: "/userManagement",
    },
    // {
    //   tittle: "Content Manage",
    //   icon: manageContentIcon,

    //   url: "/manageContent",
    // },
    // {
    //   tittle: "Subscription Management",
    //   icon: subsribtionIcon,

    //   url: "/subscribtionManage",
    // },
    {
      tittle: "Reported User",
      icon: reportedUsers,
      url: "/reportManagement",
    },
    {
      tittle: "Reported Feeds",
      icon: reportedFeed,
      url: "/reportsFeed",
    },
   
    {
      tittle: "Log Out",
      icon: logoutIcon,
      url: "/login",
    }
  ];

  return (
    <>
      <div className="d-flex flex-column mainDiv">
        <ul className="d-flex ps-0 flex-column mb-5">
          <li
            className={`d-flex justify-content-center  mb-4  list-unstyled  `}
          >
            <CImage src={appLogo} width={100} height={100} />
          </li>
          <li className="d-flex  ms-4 ">
            <CImage src={logoIcon} width={30} className="me-3" height={30} />
            <span
              className="my-2 "
              style={{ fontWeight: 500, fontSize: "16px" }}
            >
              Murray Forbes
            </span>
          </li>
          <div className="border mt-0 mb-4  border-1"></div>
          {sideBarData.map((item) => {
            return (
              <NavLink
                className="btn my-2 border-0 "
                key={item.tittle}
                to={item.url}
              >
                <li className={`d-flex  py-3 ps-1  list-unstyled ms-0`}>
                  <img
                    alt="icon"
                    className="me-3"
                    width={30}
                    height={30}
                    src={item.icon}
                  />

                  <span
                    className="mt-1 "
                    style={{ fontWeight: 500, fontSize: "16px" }}
                  >
                    {item.tittle}
                  </span>
                </li>
              </NavLink>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Navbar;
