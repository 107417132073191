import React, { useEffect,useState } from 'react'
import Table from '../../Components/Table/Table'
import Header from '../../Components/Header/Header'
import { useNavigate } from 'react-router-dom'
import "./ReportManagement.css"
import Pagination from 'react-js-pagination'
import { getRequest } from '../../Constants/apiMethods'
import { reportFeed } from '../../Constants/apiRoutes'
import moment from 'moment'

const ReportFeed = () => {
const headingTable=[
  "S.No",
  "Author name",
  "Reported Count",
  "Created Feed Date",
  "Action"
]
const navigate=useNavigate();
const [totalItemsCount, setTotalItemsCount] = useState(0);
const [activePage, setActivePage] = useState(1);
const[feedList,setFeedList]=useState([])
const itemsPerPage = 10;
const handlePageClick = (pageNumber) => {
  setActivePage(pageNumber);
};
const getFeedListing=()=>{
  getRequest(`${reportFeed}?page=${activePage}&page_size=${itemsPerPage}`)
  .then((response)=>{
    setTotalItemsCount(response.data.data.count);
     setFeedList(response?.data?.data?.results);
  })
  .catch((error)=>{
    console.log(error);
  })


}
useEffect(() => {
  getFeedListing();
}, [activePage])

  return (
    <>
     <Header title="Reported Feeds"  />
     <Table
      headerData={headingTable}
      userList={
        feedList&&feedList.map((item,index)=>{
          return(
            <tr key={item?.id}>
            <td>{itemsPerPage * (activePage - 1) + index + 1}</td>
            <td>{item?.author_name}</td>
            <td>{item?.report_count}</td>
            <td>{item?.created_date_time?moment(item.created_date_time).format("MM-DD-YYYY") :""}</td>
            <td>
            <span
            className="viewButton"
            onClick={()=> navigate(`/reportsFeed/${item?.that_id}`)}
          >View Detail</span>
  
            </td>
  
          </tr>
          )

        })
       
       
        
    
        
        
      }
     />
       <div className="d-flex  my-4 pb-2 justify-content-end me-4 ">
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItemsCount}
          // totalItemsCount={10}
          pageRangeDisplayed={5}
          // prevPageText={false}
          // nextPageText=""
          prevPageText="<<"
          nextPageText=">>"
          onChange={(pageNumber) => handlePageClick(pageNumber)}
        />
      </div>

    </>
  )
}

export default ReportFeed