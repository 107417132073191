import axios from "axios";

const base_url = process.env.React_App_Base_Url_Api;

const axiosInstance = axios.create({
  baseURL: base_url,
});

const getToken = () => {
  let token = localStorage.getItem("token");

  if (token) {
    return token;
  }

  return "";
};

axiosInstance.interceptors.response.use(
  (response) => {

    return response;
  },

  (error) => {

    if (error.response) {

      if (error.response.status === 400) {
        if (error.response.data.error.message === "Please enter valid OTP.") {
          console.log("invalid OTP , PLEASE ENTER A VALID OTP ");
        }

      }
      if (error.response.status === 401) {

        console.log(error.response.data.error.message);
      }

      if (error.response.status === 409) {

        console.log(error.response.data.error.message);
      }

      if (error.response.status === 500) {

        console.log(error.response.data.error.err.errors[0].message);
      }
    }


    return Promise.reject(error);
  }
);

export const uploadFile = (body) => {
  return fetch(`${base_url}/upload-file`, {
    body: body,

    headers: {
      Accept: "application/json",

      Authorization: `Token ${getToken()}`,
    },

    method: "POST",
  }).then((res) => res.json());
};

// Send a POST request

export const postRequest = (url, data, isImage = false) => {
  if (
    url === "/custom_admin/admin_login" ||
    url === "/custom_admin/forgot_password" ||
    url === "/admin/reset_password"
  ) {
    return axiosInstance({
      method: "post",

      url,

      data,
    });
  }

  const header = {
    authorization: `Token ${getToken()}`,
  };

  if (isImage) header["Content-Type"] = "multipart/form-data";

  return axiosInstance({
    method: "post",

    url,

    data,

  });
};

// Send a GET request

export const getRequest = (url, data) => {
  return axiosInstance({
    method: "get",

    url,

    data,

    headers: { authorization: `Token ${getToken()}` },
  });
};

// Send a DELETE request

export const deleteRequest = (url) => {
  return axiosInstance({
    method: "delete",

    url,
    headers: { authorization: `Token ${getToken()}` },
  });
};

// Send a PUT request

export const putRequest = (url, data) => {
  return axiosInstance({
    method: "put",

    url,

    data,

    headers: { authorization: `Token ${getToken()}` },
  });
};

