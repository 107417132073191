import React from "react";
import Header from "../../Components/Header/Header";

const Dashboard = () => {
  return (
    <>
      <Header title="Dashboard" />
    </>
  );
};

export default Dashboard;
