// Onboarding
export const adminLogin = "/custom_admin/admin_login";
export const forgot_password = "/custom_admin/forget_password";
export const reset_password = "/custom_admin/reset_password";
//UserManagement
export const getUserList = "custom_admin/user_management";
export const putUser = "custom_admin/user_status";
export const deleteUser = "custom_admin/delete_user";
export const getUserById = "custom_admin/user_management/";
//Report Feed
export const reportFeed = "custom_admin/list_feed_admin";
export const feedDetail="custom_admin/retrieve_feed_admin/"
export const deleteFeed="custom_admin/delete_feed_admin/"
// Reported User
export const reportedUser="custom_admin/list_blocked_user_admin"
export const deleteReportedUser="custom_admin/delete_by_admin/"

