import React from "react";
import { Routes, Route, Navigate,BrowserRouter as Router } from "react-router-dom";
import PrivateLayout from "../src/Components/PrivateLayout/Layout"
import {PrivateRoutes} from "../src/Routes/PrivateRoutes"
import {PublicRoutes} from "../src/Routes/PublicRoutes"
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";

function AppRoutes() {
  return (
    <>
      <ToastContainer limit={1} position="top-center" autoClose={2000} />

      <Router>
        <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />

          {PublicRoutes.map((item, index) => {
            return (
              <Route
                key={item.element}
                path={item.path}
                element={item.element}
              />
            );
          })}

          {PrivateRoutes.map((item, index) => {
            return (
              <Route
                key={item.element}
                path={item.path}
                element={
                  <PrivateLayout headerTitle={item.headerTitle}>
                    {item.element}
                  </PrivateLayout>
                }
              />
            );
          })}
        </Routes>
      </Router>
    </>
  );
}

export default AppRoutes;
