import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { ArrowLeft } from "react-bootstrap-icons";

import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import PublicLayout from "../../../Components/PublicLayout/PublicLayout";
const GetOtp = () => {
  const [ResendBtnDisabled, setResendBtnDisabled] = useState(true);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    navigate("/passwordreset");
  };

  setTimeout(() => setResendBtnDisabled(false), 30000);
  const resendOtpbtn = (e) => {
    e.preventDefault();
    setResendBtnDisabled(true);
  };

  return (
    <PublicLayout>
      <div className=" min-vh-100 d-flex w-50 flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol>
              <CForm onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-5">
                  <ArrowLeft
                    role="button"
                    size={26}
                    onClick={() => navigate("/getEmail")}
                  />
                </div>
                <div>
                  <h1
                    className=" login-heading-label"
                    style={{
                      fontWeight: 400,
                      fontSize: "35px",
                    }}
                  >
                    Verification OTP
                  </h1>
                  <p
                    className="text-medium-emphasis"
                    style={{ fontWeight: 400, fontize: "18px" }}
                  >
                    Please check your email
                  </p>
                </div>
                <p className="text-medium-emphasis">OTP </p>
                <CInputGroup className="mb-3 ">
                  <CFormInput
                    {...register("otp", { required: true, maxLength: 6 })}
                    style={{
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                    type="number"
                    placeholder="Enter OTP"
                    // autoComplete="off"
                  />
                </CInputGroup>
                {errors.otp?.type === "required" && (
                  <p className="text-danger text-xs italic">OTP is required</p>
                )}
                {errors.otp?.type === "maxLength" && (
                  <p className="text-danger text-xs italic">
                    Max length exceeded
                  </p>
                )}
                <CRow>
                  <div className="d-flex justify-content-end">
                    <button
                      color="link"
                      className="text-rights btn border-0"
                      disabled={ResendBtnDisabled}
                      onClick={(e) => resendOtpbtn(e)}
                    >
                      Resend OTP
                    </button>
                  </div>
                  <CCol className="d-flex justify-content-start ">
                    <CButton
                      className="px-4 py-3"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        background: "#C73866",
                        color: "white",
                        border: "0",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                      type="submit"
                    >
                      Submit
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </PublicLayout>
  );
};
export default GetOtp;
