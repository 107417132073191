import React from "react";

const Header = ({ title, searchbtn }) => {
  return (
    <>
      <header className="">
        <div className="row  w-100 my-4 align-items-center">
          <div className=" col-8" style={{ fontWeight: 700, fontSize: "24px" }}>
            {title}
          </div>
          <div className="col-3">{searchbtn}</div>
          {/* <div className="col-3">
            <div className="d-flex align-items-center justify-content-end border-start">
              <div
                className="ps-5 pe-2"
                style={{ fontWeight: 600, fontSize: "14px" }}
              >
                Murray Forbes
              </div>
              <div className=" ">
                <img src={applogo} alt="App Logo" width={50} height={50} />
              </div>
            </div>
          </div> */}
        </div>
      </header>
    </>
  );
};

export default Header;
