import React from "react";

const About = () => {
  return (
    <>
      <textarea
        name=""
        placeholder="Enter Content here"
        id=""
        cols="150"
        rows="27"
      ></textarea>
      <div
        className="border rounded w-25 mx-3 py-3 d-flex justify-content-center mb-3 "
        style={{
          background: " #C73866",
        }}
      >
        <button
          className="btn border-0 text-white "
          style={{ fontSize: "20px", fontWeight: "500" }}
        >
          Update Content
        </button>
      </div>
    </>
  );
};

export default About;
