import React from "react";

const Table = (props) => {
  return (
    <>
      <div
        className="table-responsive px-2 py-5 bg-white"
        style={{ borderRadius: "20px" }}
      >
        <table className="w-100 mb-0  table">
          <thead className="rounded-top">
            <tr
              className="justify-between  text-white "
              style={{ background: "#C73866" }}
            >
              {props.headerData &&
                props.headerData.map((item, index) => {
                  return (
                    <th key={item} className="">
                      {item}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody className="">{props.userList}</tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
