import React, { useRef } from "react";
import { useForm } from "react-hook-form";

import { ArrowLeft } from "react-bootstrap-icons";
import "../../UserManagement/UserManagement.css";
import { toast } from "react-toastify";

import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import PublicLayout from "../../../Components/PublicLayout/PublicLayout";
import { postRequest } from "../../../Constants/apiMethods";
import { reset_password } from "../../../Constants/apiRoutes";
const PasswordReset = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {

      postRequest(reset_password, data)
        .then((res) => {
          navigate("/login");
          toast.success(res.data.data.message);
          console.log(res);
        })
        .catch((error) => {
          console.log(
            "ERROR OCCURED WHILE HITTING THE RESET PASSWORD API ",
            error.message
          );
          toast.error(error.response.data.error.message);
        });

  };
  const password = useRef({});
  password.current = watch("password", "");

  return (
    <PublicLayout>
      <div className=" min-vh-100 d-flex w-50 flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol>
              <CForm onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-5">
                  <ArrowLeft
                    role="button"
                    size={26}
                    onClick={() => navigate("/getEmail")}
                  />
                </div>
                <div>
                  <h1
                    className=" login-heading-label"
                    style={{
                      fontWeight: 400,
                      fontSize: "35px",
                    }}
                  >
                    Create New Password
                  </h1>
                  <p
                    className="text-medium-emphasis"
                    style={{ fontWeight: 400, fontize: "18px" }}
                  >
                    The password must consist of 8 characters with a combination
                    of letters and numbers .
                  </p>
                </div>

                <p className="text-medium-emphasis">Password </p>
                <CInputGroup className="mb-3 ">
                  <CFormInput
                    {...register("password", {
                      required: true,
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/,
                      },
                    })}
                    style={{
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                    placeholder="New Password"
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="password"
                  />
                </CInputGroup>
                {errors.password?.type === "required" && (
                  <p className="text-danger text-xs italic">
                    Password is required
                  </p>
                )}
                {errors.password?.type === "pattern" && (
                  <p className="text-danger text-xs italic">
                    Please enter a Password consists of length 8 to 15
                    characters, 1 upper case, lower case, special character and
                    number
                  </p>
                )}
                <p className="text-medium-emphasis">Confirmation Password </p>
                <CInputGroup className="mb-3 ">
                  <CFormInput
                    name="confirm_password"
                    id="confirm_password"
                    style={{
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                    {...register("confirm_password", {
                      validate: (value) =>
                        value === password.current ||
                        "The passwords do not match",
                    })}
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="off"
                  />
                </CInputGroup>
                {errors.confirm_password && (
                  <p className="text-danger text-xs italic">
                    {errors.confirm_password.message}
                  </p>
                )}
                <p className="text-medium-emphasis">OTP </p>
                <CInputGroup className="mb-3 ">
                  <CFormInput
                    {...register("token", { required: true, maxLength: 6 })}
                    style={{
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                    type="number"
                    name="token"
                    placeholder="Enter OTP"
                  />
                </CInputGroup>
                {errors.token?.type === "required" && (
                  <p className="text-danger text-xs italic">OTP is required</p>
                )}
                {errors.token?.type === "maxLength" && (
                  <p className="text-danger text-xs italic">
                    Max length exceeded
                  </p>
                )}

                <CRow>
                  <CCol className="d-flex justify-content-start ">
                    <CButton
                      className="px-4 py-3"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        background: "#C73866",
                        color: "white",
                        border: "0",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                      type="submit"
                    >
                      Submit
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </PublicLayout>
  );
};
export default PasswordReset;
