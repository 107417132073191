import React,{useState,useEffect} from "react";
import Header from "../../Components/Header/Header";
import Table from "../../Components/Table/Table";
import Model from "../../Components/Model/Model";
import {
  
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import "./ReportManagement.css"
import Pagination from "react-js-pagination";
import { deleteRequest, getRequest } from "../../Constants/apiMethods";
import { deleteReportedUser, reportedUser } from "../../Constants/apiRoutes";
import moment from "moment";
import { toast } from "react-toastify";


function ReportedManagement() {
  const headingFileds = [
    "S.No.",
    "Reported By",
    "Reported User",
    "Reported Date",
    "View Reason",
    "Action",
  ];
  const [visible, setVisible] = useState(false);
  const [reasonContent, SetReasonContent] = useState("");
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [getReportUser,setReportUser]=useState([])
  const itemsPerPage = 10;
  const[reportUserID,setReportUserID] = useState("")




  const handleViewReason = (data) => {   
    
    // Maping for the reason is giving below
    // SPAM = 1
    // BULLYING = 2
    // HATE_SPEECH = 3
    // VIOLENT = 4
    // OTHERS = 5

    let reason; // Default value if none of the conditions match

    switch (data?.reason) {
        case 1:
            reason = "Its a Spam";
            break;
        case 2:
            reason = "Bullying or Harrasment";
            break;
        case 3:
            reason = "Hate Speech or Symbols";
            break;
        case 4:
            reason = "Violent";
            break;
        case 5:
            reason = data?.remark || "Other";
            break;
        default:
            reason = "error";
            break;
    }
    

     SetReasonContent(reason);
     setVisible(!visible);
  };
  
  const handlePageClick = (pageNumber) => {
     setActivePage(pageNumber);
  };
  const handleDelelteUser = () => {
    deleteRequest(deleteReportedUser+reportUserID)
    .then((response)=>{
      toast.success(response.data.data.message);
      setVisibleDelete(false)
      getReportedUserListing()
    })
    .catch((error)=>{
      console.log(error);
    setVisibleDelete(false)
      
    })
    
  };
  const getReportedUserListing=()=>{
    getRequest(`${reportedUser}?page=${activePage}&page_size=${itemsPerPage}`)
    .then((response)=>{
      setReportUser(response?.data?.data?.results)
      setTotalItemsCount(response.data.data.count);

    })
    .catch((error)=>{
      console.log(error);
    })
  }
  useEffect(()=>{
    getReportedUserListing();
  },[activePage])

  return (
    <>
      <Header title="Reported User Management" />
      {/* <!-- Scrollable modal --> */}

      <CModal
        scrollable
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel2"
      >
        <CModalHeader>
          <CModalTitle id="ScrollingLongContentExampleLabel2">
            View Reason
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>{reasonContent}</p>
        </CModalBody>
        <CModalFooter>
          <button
          className="closeButton"
           onClick={() => setVisible(false)}>
            Close
          </button>
        </CModalFooter>
      </CModal>
      <Model
        title="Delete User"
        body="Are you sure to delete this user ?"
        handleChange="Delete"
        handleClick={handleDelelteUser}
        visible={visibleDelete}
        setVisible={setVisibleDelete}
      />

      <Table
        headerData={headingFileds}
        userList={
          getReportUser && getReportUser.map((data,index)=>{
            return(
              <tr key={index?.id}>
              <td>{itemsPerPage * (activePage - 1) + index + 1}</td>
              <td>{data?.blocked_by_full_name}</td>
              <td>{data?.user_full_name}</td>
              {/* <td>{"1"}</td> */}
              <td>{data?.created_at?moment(data?.created_at).format("MM-DD-YYYY") :""}</td>
              <td>
              <span
          className="viewButton"
          onClick={() => handleViewReason(data)}
         
        >View Reason</span>
              </td>
              <td>
              <button type="button" 
              className="closeButton"
              onClick={()=>{setVisibleDelete(true)
                setReportUserID(data?.blocked_user_id)
              }}
>
          Delete User
        </button>
              </td>
            </tr>

            )
          })
            
           
           
          
        }
      />
      <div className="d-flex  my-4 pb-2 justify-content-end me-4 ">
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItemsCount}
          // totalItemsCount={10}
          pageRangeDisplayed={5}
          // prevPageText={false}
          // nextPageText=""
          prevPageText="<<"
          nextPageText=">>"
          onChange={(pageNumber) => handlePageClick(pageNumber)}
        />
      </div>
    </>
  );
}

export default ReportedManagement;
