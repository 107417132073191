import React, { useEffect, useState } from "react";
import { CImage, CCarousel, CCarouselItem } from "@coreui/react";
import userImg from "../../../assets/Icons/userImg.png";
import userPets from "../../../assets/Icons/userPets.png";
import Header from "../../../Components/Header/Header";
import "../../../App.css";
import { getRequest } from "../../../Constants/apiMethods";
import { getUserById } from "../../../Constants/apiRoutes";
import { useParams,useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-bootstrap-icons";

export const UserDetail = () => {
  const [userDetail, setUserDetail] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [petInfo, setPetInfo] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  const getUser = () => {

      getRequest(getUserById + id)
        .then((res) => {
          setUserDetail(res.data.data.profile_info);
          setUserInfo(res.data.data.profile_info.user_info);
          setPetInfo(res.data.data.profile_info.pet_info);
        })
        .catch((error) => {
          console.log("Error Message", error.message);
        });

  };
  useEffect(() => {
    getUser();
  }, []);



  return (
    <>
      <Header title="User Management" />
      <div>
        <ArrowLeft
          role="button"
          size={26}
          onClick={() => navigate("/userManagement")}
        />
      </div>
      <div className=" border  rounded bg-white mt-4 common-user-detail-card">
        <div>
          <h5
            className="p-3 text-white border-bottom rounded-top rounded-bottom"
            style={{
              background: "#C73866",
            }}
          >
            Personal Details
          </h5>
        </div>
        <div className="row ">
          <div className="col-10 row  ">
            <div className="col-sm-6">
              <ul className="list-unstyled ps-3">
                <li className="py-2">
                  <h5>
                    Name:
                    <span className="ms-2" style={{ color: "#c73866" }}>
                      {" "}
                      {userDetail.full_name ? userDetail.full_name : "--"}
                    </span>
                  </h5>
                </li>
                <li className="py-2">
                  <h5>
                    Gender:
                    <span className="ms-2" style={{ color: "#c73866" }}>
                      {userInfo.gender ? userInfo.gender : "--"}
                    </span>
                  </h5>
                </li>
                <li className="py-2">
                  <h5>
                    Your Story:
                    <span className="ms-2" style={{ color: "#c73866" }}>
                      {userInfo.user_story ? userInfo.user_story : "--"}
                    </span>
                  </h5>
                </li>
              </ul>
            </div>
            <div className="col-sm-6">
              <ul className="list-unstyled ps-3">
                <li className="py-2">
                  {" "}
                  <h5>
                    Sun-Sign:
                    <span className="ms-2" style={{ color: "#c73866" }}>
                      {userInfo.sun_sign ? userInfo.sun_sign : "--"}
                    </span>
                  </h5>
                </li>
                <li className="py-2">
                  <h5>
                    Passion:
                    <span className="ms-2" style={{ color: "#c73866" }}>
                      {userInfo.profession ? userInfo.profession : "--"}
                    </span>
                  </h5>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-2  mb-2">
            <CCarousel controls indicators transition="crossfade">
            {userInfo?.user_avatar &&
                userInfo?.user_avatar.map((item, index) => {
                  return (
                    <CCarouselItem key={index}>
                    <CImage
                      className="d-block h-auto w-100"
                      src={item}
                      alt={`slide ${index + 1}`}
                    />
                  </CCarouselItem>
                  );
                })}
                
              {/* <CCarouselItem>
                <CImage
                  className="d-block h-auto w-100"
                  src={userImg}
                  alt="slide 1"
                />
              </CCarouselItem> */}
              {/* <CCarouselItem>
                <CImage
                  className="d-block h-auto w-100"
                  src={userPets}
                  alt="slide 2"
                />
              </CCarouselItem>
              <CCarouselItem>
                <CImage
                  className="d-block h-auto w-100"
                  src={userImg}
                  alt="slide 3"
                />
              </CCarouselItem> */}
            </CCarousel>
          </div>
        </div>
      </div>
      <div className="border rounded bg-white mt-4 common-user-detail-card">
        <div>
          <h5
            className="p-3 text-white border-bottom rounded-top rounded-bottom"
            style={{
              background: "#C73866",
            }}
          >
            Pet Details
          </h5>
        </div>
        <div className="row ">
          <div className="col-10 row">
            <div className=" col-md-6">
              <ul className="list-unstyled ps-3">
                <li className="py-2">
                  <h5>
                    Pet Type:
                    <span className="ms-2" style={{ color: "#c73866" }}>
                      {" "}
                      {petInfo.pet_type ? petInfo.pet_type : "--"}
                    </span>
                  </h5>
                </li>
                <li className="py-2">
                  <h5>
                    Pet Name:
                    <span className="ms-2" style={{ color: "#c73866" }}>
                      {petInfo.pet_name ? petInfo.pet_name : "--"}
                    </span>
                  </h5>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul className="list-unstyled ps-3">
                <li className="py-2">
                  {" "}
                  <h5>
                    Pet Age:
                    <span className="ms-2" style={{ color: "#c73866" }}>
                      {petInfo.age ? petInfo.age : "--"}
                    </span>
                  </h5>
                </li>
                <li className="py-2">
                  <h5>
                    Pet Story:
                    <span className="ms-2" style={{ color: "#c73866" }}>
                      {petInfo.pet_story ? petInfo.pet_story : "--"}
                    </span>
                  </h5>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-2 mb-2">
            <CCarousel controls indicators transition="crossfade">
            {petInfo?.pet_avatar &&
                petInfo?.pet_avatar.map((item, index) => {
                  return (
                    <CCarouselItem key={index}>
                    <CImage
                      className="d-block h-auto w-100"
                      src={item}
                      alt={`slide ${index + 1}`}
                    />
                  </CCarouselItem>
                  );
                })}
              {/* <CCarouselItem>
                <CImage
                  className="d-block h-auto w-100"
                  src={userPets}
                  alt="slide 1"
                />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={userImg} alt="slide 2" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage
                  className="d-block w-100"
                  src={userPets}
                  alt="slide 3"
                />
              </CCarouselItem> */}
            </CCarousel>
          </div>
        </div>
      </div>
      {/* Subscription Details */}

      {/* <div className=" border rounded bg-white  my-4">
        <div>
          <h5
            className="p-3 text-white border-bottom rounded-top rounded-bottom"
            style={{
              background: "#C73866",
            }}
          >
            Subscription Details
          </h5>
        </div>
        <div className="row">
          <div className="row col-md-10">
            <ul className="list-unstyled row ps-4">
              <li className=" col-6 ">
                <div>
                  <h5 className="py-2">
                    Plan Type:
                    <span className="ms-2 " style={{ color: "#c73866" }}>
                      {" "}
                      Monthly
                    </span>
                  </h5>
                </div>
                <div>
                  {" "}
                  <h5 className="py-2">
                    Purchased On:
                    <span className="ms-2" style={{ color: "#c73866" }}>
                      4 August 2022
                    </span>
                  </h5>
                </div>
              </li>
              <li className=" col-6">
                <h5>
                  Renewal Date:
                  <span className="ms-2" style={{ color: "#c73866" }}>
                    5 August 2022
                  </span>
                </h5>
              </li>
            </ul>
          </div>
          <div className="clearfix col-md-2 me-4" style={{ width: "229" }}>
            <CImage
              className="w-100 "
              align="center"
              rounded
              src={SubscribtionInfoImg}
            />
          </div>{" "}
        </div>
      </div> */}
      {/* Bottom Button */}
      {/* <div className="d-flex justify-content-center my-4">
        <button className=" border-0 border rounded mx-2 py-2 btnHover">
          Deactivate User
        </button>

        <button className=" border-0 border rounded mx-2 py-2 btnHover">
          Edit User
        </button>

        <button className=" border-0 border rounded mx-2 py-2 btnHover">
          Delete User
        </button>

        <button className=" border-0 border rounded mx-2 py-2 btnHover">
          Send User
        </button>
      </div> */}
    </>
  );
};
