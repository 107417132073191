import React, { useState, useEffect } from "react";
import searchicon from "../../assets/Icons/searchIcon.svg";
import Table from "../../Components/Table/Table";
import { CFormSelect } from "@coreui/react";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import Model from "../../Components/Model/Model";
import "./UserManagement.css";
import Header from "../../Components/Header/Header";
import { getRequest, putRequest } from "../../Constants/apiMethods";
import { deleteUser, getUserList, putUser } from "../../Constants/apiRoutes";
import moment from "moment";

const UserManagement = () => {
  const field = [
    "S.No",
    "User Name",
    "Email Address",
    "Phone",
    "Subscription Type",
    "Date Joined",
    "Status",
    "Actions",
  ];
  const optStyle = {
    // border: "1px solid rgba(0, 0, 0, 0.53)",
    borderRadius: "10px",
    background: "rgba(0, 0, 0, 0.8)",
    color: "White",
    borderBottom: "2px solid white",
  };
  let navigate = useNavigate();
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleBlock, setVisibleBlock] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [deleteUserId, setDeleteUserId] = useState();
  const [blockUserId, setBlockUserId] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [userList, setUsers] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [userId, setUserId] = useState();
  const [search, setSearch] = useState("");
  const [searchTextTimeout, setSearchTextTimeout] = useState(null);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  useEffect(() => {
    getUser();
  }, [activePage]);
  const itemsPerPage = 10;

  const getUser = (searchText = "") => {

      getRequest(
        `${getUserList}?search=${searchText}&page=${activePage}&page_size=${itemsPerPage}`
      )
        .then((res) => {
          setUsers(res.data.data.results);
          setTotalItemsCount(res.data.data.count);
        })
        .catch((error) => {
         
          console.log("Error Message===>", error.message);
        });

  };
  const handleView = (id) => {
    return navigate(`profile/${id}`);
  };
  const handleOptionChange = (index, data, id, is_active) => {
    if (data.target.value === "delete") {
      setDeleteUserId(id);
      setVisibleDelete(true);
      console.log("Delete User Id ===", id);
    }
    if (data.target.value === "block") {
      setBlockUserId(index);
      setUserId(id);
      setIsActive(is_active);
      setVisibleBlock(true);
      console.log("Block user", "Id===", id);
    }
  };
  const handleDelete = () => {
    let userdata = [...userList];
    let deleteUserdata = userdata.filter((i) => i.id !== deleteUserId);

    const delData = { user_id: deleteUserId };

      putRequest(deleteUser, delData)
        .then((res) => {
          setUsers(deleteUserdata);
        })
        .catch((error) => {
          console.log("Error Message", error.message);
        });

    setVisibleDelete(false);
  };
  const handleBlock = () => {
    const blockData = { user_id: userId, is_active: !isActive };

      putRequest(putUser, blockData)
        .then((res) => {
          [...userList][blockUserId].is_active = !isActive;
          setIsActive(!isActive);
        })
        .catch((error) => {
          console.log("Error Message", error.message);
        });

    setVisibleBlock(false);
  };

  const handleSearch = (val) => {
    setSearch(val);
    if (searchTextTimeout) {
      clearTimeout(searchTextTimeout);
    }

    setSearchTextTimeout(
      setTimeout(() => {
        getUser(val);
      }, 500)
    );
  };
  // Pagination

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    if(currentItems === null || currentItems?.length === 0) {
      setCurrentItems(userList.slice(itemOffset, endOffset));
    }
  }, [itemOffset, itemsPerPage,userList]);

  const handlePageClick = (pageNumber) => {
    const newOffset = (pageNumber * itemsPerPage) % userList.length;
  
    setItemOffset(newOffset);
    setActivePage(pageNumber);
  };
  const searchbtn = (
    <div
      className="d-flex justify-content-between"
      style={{
        border: "1px solid black",
        borderRadius: "40px",
        background: "white",
      }}
    >
      <div className="">
        <input
          type="text"
          value={search}
          className="py-2 w-75 border-0 ms-4 "
          placeholder="Search here"
          style={{ outline: "none" }}
          onChange={({ target }) => handleSearch(target.value)}
        />
      </div>
      <div className="">
        {" "}
        <img className="mt-2" src={searchicon} alt="" />
      </div>
    </div>
  );
  const actions = (index, id, is_active) => {
    return (
      <>
        <CFormSelect
          className="form-control  pe-2 w-75 selectStyle"
          onChange={(data) => handleOptionChange(index, data, id, is_active)}
          value="0"
          style={{ minWidth: "95px" }}
        >
          <option value="0" hidden>
            Actions
          </option>
          <option className="btn  " style={optStyle} value="delete">
            Delete
          </option>
          <option className="btn  " style={optStyle} value="block">
            {is_active ? "Block" : "Unblock"}
          </option>
        </CFormSelect>

        <button
          className="btn border-0"
          onClick={() => handleView(id)}
          style={{ color: "#c73866" }}
        >
          View
        </button>
      </>
    );
  };
  return (
    <>
      <Header title="User Management" searchbtn={searchbtn} />

      <Model
        title="Delete User"
        body="Are you sure to delete this user ?"
        handleChange="Delete"
        handleClick={handleDelete}
        visible={visibleDelete}
        setVisible={setVisibleDelete}
      />
      <Model
        title={isActive ? "Block User" : "Unblock User"}
        body={
          isActive
            ? "Are you sure to Block this user ?"
            : "Are you sure to Unblock this user ?"
        }
        handleChange={isActive ? "Block" : "Unblock"}
        handleClick={handleBlock}
        visible={visibleBlock}
        setVisible={setVisibleBlock}
      />

      <Table
        headerData={field}
        userList={
          userList &&
          userList.map((item, index) => {
            return (
              <tr key={item?.id}>
                <td>{itemsPerPage * (activePage - 1) + index + 1}</td>
                <td>{item.full_name}</td>
                <td>{item.email}</td>
                <td>{item.mobile_number}</td>
                <td>{item.subscription}</td>
                {/* <td>{item.joining_date}</td> */}
            
                <td>{item.joining_date ? moment(item.joining_date).format("MM-DD-YYYY") :""}</td> 
                <td>{item.is_active ? "Active" : "Blocked"}</td>
                <td className="d-flex ">
                  {actions(index, item.id, item.is_active)}
                </td>
              </tr>
            );
          })
        }
      />
      <div className="d-flex  my-4 pb-2 justify-content-end me-4 ">
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItemsCount}
          // totalItemsCount={10}
          pageRangeDisplayed={5}
          // prevPageText={false}
          // nextPageText=""
          prevPageText="<<"
          nextPageText=">>"
          onChange={(pageNumber) => handlePageClick(pageNumber)}
        />
      </div>
    </>
  );
};

export default UserManagement;
