import React from "react";
import { useForm } from "react-hook-form";
import { postRequest } from "../../../Constants/apiMethods";
import { NavLink, useNavigate } from "react-router-dom";
import PublicLayout from "../../../Components/PublicLayout/PublicLayout";
import { adminLogin } from "../../../Constants/apiRoutes";
import { toast } from "react-toastify";

import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
} from "@coreui/react";
const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    data = { user_role: 1, ...data };
    
      postRequest(adminLogin, data)
        .then((res) => {
          const token = res.data.data.token;
          localStorage.setItem("token", token);
          navigate("/userManagement");
          toast.success("Welcome !!!");

        })
        .catch((error) => {
          toast.error(error.response.data.error.message);
          console.log("Error Message===>", error);
        });
  
  };
  return (
    <>
      <PublicLayout>
        <div className=" min-vh-100 d-flex w-50 flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol>
                <CForm onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <h1
                      className=" login-heading-label"
                      style={{
                        fontWeight: 400,
                        fontSize: "35px",
                      }}
                    >
                      Welcome to Scratch
                    </h1>
                    <p
                      className="text-medium-emphasis"
                      style={{ fontWeight: 400, fontize: "18px" }}
                    >
                      Login to start managing
                    </p>
                  </div>
                  <p className="text-medium-emphasis">Email</p>
                  <CInputGroup className="mb-3 ">
                    <CFormInput
                      autoComplete="off"
                      {...register("email", {
                        required: true,
                        pattern:
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      })}
                      style={{
                        borderRadius: "10px",
                        padding: "10px",
                      }}
                      placeholder=" Email address"
                    />
                  </CInputGroup>
                  {errors.email?.type === "required" && (
                    <p className="text-danger text-xs ">Email is required</p>
                  )}
                  {errors.email?.type === "pattern" && (
                    <p className="text-danger  text-xs ">
                      Please enter a valid Email address
                    </p>
                  )}
                  <p className="text-medium-emphasis">Password</p>
                  <CInputGroup className="mb-4">
                    <CFormInput
                      autoComplete="off"
                      {...register("password", {
                        required: true,
                        pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/,
                      })}
                      style={{
                        borderRadius: "10px",
                        padding: "10px",
                      }}
                      type="password"
                      placeholder=" Password"
                    />
                  </CInputGroup>
                  {errors.password?.type === "required" && (
                    <p className="text-danger text-xs italic">
                      Password is required
                    </p>
                  )}
                  {errors.password?.type === "pattern" && (
                    <p className="text-danger text-xs italic">
                      Please enter a Password consists of length 8 to 15
                      characters, 1 upper case, lower case, special character
                      and number
                    </p>
                  )}
                  <CRow>
                    <div className="d-flex justify-content-end">
                      <NavLink
                        className="text-rights btn  text-decoration-none border-0"
                        to="/getEmail"
                      >
                        Forgot password?
                      </NavLink>
                    </div>
                    <CCol className="d-flex justify-content-start ">
                      <CButton
                        className="px-4 py-3"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          background: "#C73866",
                          color: "white",
                          border: "0",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                        type="submit"
                      >
                        Login
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </PublicLayout>
    </>
  );
};
export default Login;
